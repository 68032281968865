
body {
    background-color: #000000;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 1600 800'%3E%3Cg %3E%3Cpolygon fill='%23070d22' points='1600 160 0 460 0 350 1600 50'/%3E%3Cpolygon fill='%230e1a44' points='1600 260 0 560 0 450 1600 150'/%3E%3Cpolygon fill='%23142666' points='1600 360 0 660 0 550 1600 250'/%3E%3Cpolygon fill='%231b3388' points='1600 460 0 760 0 650 1600 350'/%3E%3Cpolygon fill='%232240aa' points='1600 800 0 800 0 750 1600 450'/%3E%3C/g%3E%3C/svg%3E");
    /* background by SVGBackgrounds.com */
    background-attachment: fixed;
    background-size: cover;

}

.banner-img {
    background-size: cover;
    object-fit: fill;
    opacity: 75%;
    display: block;
}

.content-block, .header-block {
    background-color: white;
}

.footer-block {
    background-color:#003399 !important;
    color: white;
}

.banner-block {
    opacity: 0.7;
}

.navbar {
    background-color:#003399 !important;
    color: white !important;
}

.nav-item > a, .nav-item > a:visited {
    color: silver;
}

.nav-item > a:hover {
    color: whitesmoke;
}