.navbar {
    display: block;
    justify-content: center !important;
}

/* ul */
.navbar-nav {
    display: block;

    list-style: none;
    list-style-type: none;
}

/* li */
.nav-item {
    display: block !important; 
    padding: 8px;
    min-width: 100px;
}


.nav-item-button {
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
    background: rgba(204, 204, 204, 0);
    color: silver;
    font-size: 20px;
}

.nav-item-button:hover{
    color: white;
}

@media (min-width: 500px) {
    .nav-item {
        display: inline-block !important;
    }
}