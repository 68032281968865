* {
    font-family: 'Open Sans', sans-serif;
}

body {
    /* font-family: Verdana, Geneva, Arial, Helvetica, sans-serif; */
    line-height: 1.5;
    word-wrap: normal;
    overflow-wrap: anywhere;
    
}

p {
    padding-top: 0.618rem;
    padding-bottom: 0.618rem;
}

.logoWrapper, .logoWrapper > * {
    width: 200px;
    text-align: center;
    font-size: 11px;
    font-style: normal;
    /* font-family: Tahoma, Verdana, Sans-Serif; */ 
    background-color: Transparent;
    text-decoration: none;
    color: Gray;
}

.content-block > .text-block {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    
    padding-bottom: 10px;
}

.footer-block {
    font-size: 0.8rem;
    padding: 1rem;
    text-align: center;
    color: silver;
}

.fixedelement {
    position: fixed;
    overflow: auto;
}


/* */

h1 {
    padding-top: 10px;
    font-weight: bold;
    font-size: 2em;
    font-weight: bold;
}

h2 {
    padding-top: 1em;
    padding-bottom: 0.5em;
    font-size: 1.5em;
    font-weight: lighter;

}

h3, #p3 {
    padding-top: 16.18px;
    padding-bottom: 6.18px;
    font-variant: small-caps;
}

h4 {
    padding: 5px 0;
    font-variant: small-caps;
}

h5 {
    padding: 5px 0;
}

h6 {
    padding: 5px 0;
    font-variant: small-caps;
}

.no-border {
    border: 0 !important;
    border-style:none !important;
}

/* Oferta */
section#oferta {
    display: block;
    padding-top: 20px;
}

section#oferta > * > ul {
    padding-left: 40px;
}

section#zacheta {
    text-align: left;
    color: deeppink;
    background-color: Transparent;
    padding-top: 20px;
    font-size: 20px;
    font-weight: bold;
    font-variant: small-caps;
}

.zacheta-button {
    color: #003399 !important;
    background-color: Transparent;
    font-size: 21px;
    font-weight: bold;
    font-variant: small-caps;
    cursor: pointer;
}

.space-big {
    display: block !important;
    padding-top: 10px;
}

h1, h2, h3 {
    color: #003399;
}


/* object-fit  https://developer.mozilla.org/pl/docs/Web/CSS/object-fit */
.fill {
    object-fit: fill;
}

.contain {
    object-fit: contain;
}

.cover {
    object-fit: cover;
}

.none {
    object-fit: none;
}

.scale-down {
    object-fit: scale-down;
}


/* */
.no-text-decoration {
    text-decoration: none;
}

.wrap{
    cursor: pointer;
}