/* @charset "UTF-8"; */
/* Reset */
*, *:before, *:after {
    box-sizing: border-box;
    border-spacing: 0;
    border: 0;

    margin: 0;
    padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}

article {
    margin-bottom: 20px;
}

.main-container {
    display: grid;
    grid-gap: 0px 0px;
    box-sizing: border-box;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:          
        "header"         
        "nv"
        "main"
        "footer";
}

.header-block {
    grid-area: header;
    padding: 1rem;
}

.nav-block {
    grid-area: nv;
    padding: 0.5rem 0rem 0.5rem 1rem;
}

.content-block {
    grid-area: main;
}

.text-block {
    margin-left: 1rem;
    margin-right: 1rem;
}

.footer-block {
    grid-area: footer;
    
}



@media (min-width: 800px) {

    .main-container {
    grid-template-columns: 1fr repeat(5, 1fr) 1fr;
    grid-template-rows: auto auto minmax(min-content, auto) auto auto;

    grid-template-areas:
        ". header header header header header ."
        ". nv nv nv nv nv ."

        ". main main main main main ."

        ". footer footer footer footer footer .";
    }

    .navbar {
        padding-left: 100px;
        padding-right: 100px;


    }

    .text-block {
        margin-left: 100px;
        margin-right: 100px;

        padding-top: 30px;
        padding-bottom: 30px;
    }
}